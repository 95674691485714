import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { ExternalLink, LinkedLogo, NamedLink } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';

import card1 from './img/card1.png';
import card2 from './img/card2.png';
import card3 from './img/card3.png';

import supportedByImg1 from './img/supported-by-img-1.jpg';
import supportedByImg2 from './img/supported-by-img-2.jpg';

import { useConfiguration } from '../../../../context/configurationContext';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [{ contentCss: css.contentCol1, gridCss: css.gridCol1 }, {
  contentCss: css.contentCol2,
  gridCss: css.gridCol2,
}, { contentCss: css.contentCol3, gridCss: css.gridCol3 }, { contentCss: css.contentCol4, gridCss: css.gridCol4 }];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const intl = useIntl();
  const {
    sectionId, className, rootClassName, numberOfColumns, socialMediaLinks, // slogan,
    appearance, copyright, blocks, options, linkLogoToExternalSite, isPartnersPage
  } = props;

  const config = useConfiguration();
  const instagramPage = config.siteInstagramPage;
  const linkedInPage = config.siteLinkedInPage;

  const slogan = {
    content: intl.formatMessage({ id: 'SectionFooter.slogan' }), fieldType: 'text',
  };

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml, blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually

  return (<SectionContainer
    as='footer'
    id={sectionId}
    className={className || css.root}
    rootClassName={rootClassName}
    appearance={appearance}
    options={fieldOptions}
  >
    <div className={css.footer}>
      {/* <div className={classNames(css.content, getContentCss(numberOfColumns))}> */}
      <div className={css.section}>
        <LinkedLogo
          className={css.logo}
          rootClassName={css.logoLink}
          logoClassName={css.logoWrapper}
          logoImageClassName={css.logoImage}
          linkToExternalSite={linkLogoToExternalSite}
          // layout={logoLayout}
          layout='footer'
        />
        {/* <div className={css.sloganDesktop}>
            <Field data={slogan} className={css.slogan} />
          </div> */}
      </div>

      <div className={classNames(css.section, css.sectionWithBtn)}>
        <h3 className={css.columnTitle}>
          <FormattedMessage id='SectionFooter.title1' />
        </h3>
        <NamedLink
          className={css.links}
          name='SearchPage'
        >
          <FormattedMessage id='SectionFooter.offersLink' />
        </NamedLink>

        <NamedLink
          className={css.links}
          name='SearchPage'
        >
          <FormattedMessage id='SectionFooter.practitionerLink' />
        </NamedLink>

        <NamedLink
          className={css.ctaButton}
          name={isPartnersPage ? 'LandingPage' : 'LandingPageClient'}
        >
          <FormattedMessage id={isPartnersPage ? 'SectionFooter.exploreHolisticOffers' : 'SectionFooter.practitionerLinkQ'} />
        </NamedLink>
      </div>

      <div className={css.section}>
        <h3 className={css.columnTitle}>
          <FormattedMessage id='SectionFooter.contactUs' />
        </h3>
        <a className={css.links}
          href='mailto:support@book-holistic.com'>Support@book-holistic.com</a>
      </div>

      <div className={css.section}>
        <h3 className={css.columnTitle}>
          <FormattedMessage id='SectionFooter.100SecurePayments' />
        </h3>
        <p className={css.links}>
          <FormattedMessage id='SectionFooter.poweredByStripe' />
        </p>
        <div className={css.cards}>
          <img src={card1} />
          <img src={card2} />
          <img src={card3} />
        </div>
      </div>

      <div className={css.section}>
        <h3 className={css.columnTitle}>
          <FormattedMessage id='SectionFooter.title3' />
        </h3>
        <ExternalLink href={instagramPage} className={css.links}>
          <FormattedMessage id='SectionFooter.instagramLink' />
        </ExternalLink>
        <ExternalLink href={linkedInPage} className={css.links}>
          <FormattedMessage id='SectionFooter.linkedInLink' />
        </ExternalLink>
      </div>

      <div className={css.section}>
        <h3 className={css.columnTitle}>
          <FormattedMessage id='SectionFooter.supportedBy' />
        </h3>
        <div className={classNames(css.cards, css.cardsColumn)}>
          <img src={supportedByImg1} />
          <img src={supportedByImg2} />
        </div>
      </div>
    </div>

    <div className={css.sectionSeparator} />

    <div className={css.sectionBottom}>
      <NamedLink
        className={css.links}
        name="CMSPage"
        params={{ pageId: 'imprint' }}
      >
        <FormattedMessage id='SectionFooter.imprintLink' />
      </NamedLink>

      <NamedLink name="CMSPage" params={{ pageId: 'terms-general' }} className={css.links}>
        <FormattedMessage id='SectionFooter.termsGeneralLink' />
      </NamedLink>

      <NamedLink className={css.links} name='TermsOfServicePage'>
        <FormattedMessage id='SectionFooter.termsLink' />
      </NamedLink>

      <NamedLink className={css.links} name='PrivacyPolicyPage'>
        <FormattedMessage id='SectionFooter.policyLink' />
      </NamedLink>
    </div>

  </SectionContainer>);
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
